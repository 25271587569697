<template>
    <div class="talks">
        <Talks />
    </div>
</template>


<script>
import Talks from "@/components/Talks.vue";
export default {
    components: {
        Talks,
    }
}
</script>