<template>
    <div class="about">
        <About :workshop="workshop"/>
    </div>
</template>


<script>
import About from "@/components/About.vue";
import workshops from "@/workshops.js";
import _ from "lodash";
export default {
    components: {
        About,
    },
    props: {
        workshopId: {
            type: String
        }
    },
    data() {
        return {
            workshop: _.sortBy(_.filter(workshops, "latest")) 
        };
    },
}
</script>