<template>
    <div class="container">
        <div class="section">
            <h1 class="title">Featured Talks</h1>
        </div>
        <div style="margin-top: 0px; margin-left: 30px; margin-bottom: 30px">
            <div>
                A collection of some of our workshop presentations. For a full listing, visit our <a style="color:blue" href="https://www.youtube.com/channel/UCNUuEywaLBmwgOLRXhFPLPw">YouTube</a> channel.
            </div>
            <br/>
            <div align="center">
                <div class="columns is-multiline">
                    <div class="column is-4" v-for="(talk, i) in talks" :key="i">
                        <div class="card">
                            <div class="card-image">
                                <iframe
                                    width="100%"
                                    height="300"
                                    :src="talk.youtubeEmbed"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="title is-6" >
                                            <a style="color:blue" :href="talk.youtubeLink">{{talk.title}}</a>
                                        </div>
                                        <div class="content">
                                            <b>{{talk.author}}</b>
                                            <br />
                                            <div>{{talk.workshop}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import talks from "@/talks.js";
import _ from "lodash";

export default {
    data() {
        return {
            talks: _.sortBy(_.filter(talks, "workshop")),
        };
    }
};
</script>

<style lang="scss" scoped>
.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>