const talks = [

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/LER6FgE5L3Q",
        youtubeLink: "https://www.youtube.com/embed/LER6FgE5L3Q",
        title: "KEYNOTE - Flight Software Comes from Mars",
        author: "Don Eyles",
        workshop: "2021 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/6YOod6mjzgU",
        youtubeLink: "https://www.youtube.com/embed/6YOod6mjzgU",
        title: "An agile space enterprise to keep pace with technology and the threat",
        author: "Kevin D. Bell",
        workshop: "2021 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/Ecod6JfzpJU",
        youtubeLink: "https://www.youtube.com/embed/Ecod6JfzpJU",
        title: "A system's vision for software technology advancement in robotic spaceflight",
        author: "S. Thibault",
        workshop: "2021 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/umQY2H1on58",
        youtubeLink: "https://www.youtube.com/embed/umQY2H1on58",
        title: "Space Exploration at Johns Hopkins APL",
        author: "Jason Kalirai",
        workshop: "2021 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/-ucyfxA0knA",
        youtubeLink: "https://www.youtube.com/watch?v=-ucyfxA0knA",
        title: "KEYNOTE - Computing in Space Past, Present and Future",
        author: "Michael McLelland - SwRI",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/rLdyBkEYqcs",
        youtubeLink: "https://www.youtube.com/watch?v=rLdyBkEYqcs&t",
        title:
            "KEYNOTE - MSFC Space Systems Department",
            author: "Steve Miley - NASA Marshall Spaceflight Center",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/REv14Gz5_lo",
        youtubeLink: "https://www.youtube.com/watch?v=REv14Gz5_lo",
        title:
            "KEYNOTE - Space policy and Artemis",
            author: "Eric Berger - Ars Technica",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/mQu9m4MG5Gc",
        youtubeLink: "https://www.youtube.com/watch?v=mQu9m4MG5Gc",
        title:
            "Mars Helicopter Presentation",
            author: "Timothy Canham - NASA Jet Propulsion Laboratory",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/11T5fx706_k",
        youtubeLink: "https://www.youtube.com/watch?v=11T5fx706_k",
        title:
            "Spacewire Analysis Through Kaitai and Wireshark",
            author: "Andrew Badger & Samuel Bibelhauser - JHU/APL",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/TqzkKEbkVqs",
        youtubeLink: "https://www.youtube.com/watch?v=TqzkKEbkVqs",
        title:
            "F Prime Flt SW Framework - Lunar Flashlight and NEA Scout Cubesats",
            author: "Aadil Rizvi - NASA Jet Propulsion Laboratory",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/PvWFxKOL7KI",
        youtubeLink: "https://www.youtube.com/watch?v=PvWFxKOL7KI&t",
        title:
            "FPGA-Instantiated Processor-in-the-Loop for FSW Development",
        author: "Luis Rodriguez",
        workshop: "2019 Workshop"
    },
    
    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/768xhqqN8tM",
        youtubeLink: "https://www.youtube.com/watch?v=768xhqqN8tM",
        title:
            "Coding Conventions and Standards",
            author: "Chad Loeffler - SwRI",
        workshop: "2019 Workshop"
    },

    {
        type: "__YOUTUBE",
        youtubeEmbed: "https://www.youtube.com/embed/jvEFU1SkLpg",
        youtubeLink: "https://www.youtube.com/watch?v=jvEFU1SkLpg",
        title:
            "SLS GNC Model-based Design Approach",
        workshop: "2019 Workshop"
    },
];

export default talks;