<template>
    <Workshop :workshop="workshop" />
</template>

<script>
import Workshop from "@/components/Workshop.vue";
import workshops from "@/workshops.js";

export default {
    components: {
        Workshop,
    },
    props: {
        workshopId: {
            type: String
        }
    },
    data() {
        return {
            workshop: workshops[this.workshopId]
        };
    }
}
</script>