<template>
    <div class="container">
        <div class="section">
            <h1 class="title">About the FSW Workshops</h1>
            <p> 
                The Workshops on Spacecraft Flight Software were started in 2007 to provide a relaxed and informal 
                environment for flight software practitioners to discuss all aspects of flight software. The idea behind the 
                workshops is that by sharing new concepts and showing what works (and what doesn't) we will increase each 
                other's chances for mission success in the exploration and use of space.
            </p>
            <br/><br/>

            <h1 class="title">2023 Organizing Committee</h1>
            <div v-for="(ws, i) in workshop" :key="i">
                <div class="columns is-multiline" v-if="ws.public==true">
                    <div class="column is-3" v-for="(coord, i) in ws.committee" :key="i">
                        <div>
                            <b>{{coord.position}}</b><br/>
                            {{coord.name}} <br/>
                            {{coord.institution}}
                        </div><br/>
                    </div>
                </div>
            </div>
            <br/>

            <h1 class="title">FSW Workshop Founders</h1>
            <br/>
            <div style="margin-top: 0px; margin-left: 30px; margin-bottom: 30px">
                <div align="center">
                    <div class="columns is-multiline">
                        <div class="column is-2" v-for="(founder, i) in founders" :key="i">
                            <a :href="founder.link">    
                                <img
                                    :src="founder.hero"
                                    :alt="founder.name"
                                    style="height: 70px; margin: auto;"
                                />
                            </a>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>

            <h1 class="title">Contact Us</h1>
            <p> For any questions please contact:  
                <a style="color:blue" href="mailto:info@flightsoftware.org?">info@flightsoftware.org</a>
            </p>

        </div>
    </div>
</template>

<script>
import sponsors from "@/sponsors.js";
import _ from "lodash";

export default {
    props: {
        workshop: {
            type: Object
        }
    },
    data() {
        return {
            sponsors: _.sortBy(_.filter(sponsors, "public")),
            founders: _.sortBy(_.filter(sponsors, "founder"))
        };
    }
};
</script>

<style lang="scss" scoped>
.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>
